@charset "UTF-8";
/* ----------------------------------------------------------------
contactページのみのスタイル
-----------------------------------------------------------------*/
#contact {
    &.contentWrap {
        padding: 0 0 100px 0;
    }
    .main-visual {
        background: url("../images/contact_main_01.jpg");
        background-size: cover;
    }
    .content_01,
    .content_02 {
        opacity: 0;
    }
}
.contactContent {
    background-color: $clrWht;
    border-top: 5px solid $clrBorder03;
    padding: 100px 0;
    .inner {
        width: 800px;
        margin: 0 auto;
    }
    p {
        line-height: 2em;
    }
    h2 {
        font-size: 22px;
        font-weight: bold;
        text-align: left;
        margin: 0 0 20px 0;
        span {
            width: 40px;
            height: 3px;
            background: $bgclr01;
            background: $bgclr01_ie;
            display: block;
        }
    }
    .textWrap {
        font-size: 24px;
        text-align: center;
        margin: 0 0 80px 0;
    }
    .borderWrap {
        border: 1px solid $clrBorder02;
        position: relative;
        margin: 0 0 80px 0;
        color: $clrBlck;
        &.type_01{
            padding: 50px 0;
            text-align: center;
        }
        &.type_02{
            padding: 40px 40px;
            text-align: left;
            .text{
                margin: 0 0 20px 0;
            }
        }
        .ttl {
            font-size: 18px;
            font-weight: bold;
            padding: 0 20px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            background-color: $clrWht;
        }
        .number {
            font-size: 35px;
            font-family: Oswald, sans-serif;
            span {
                font-size: 14px;
            }
        }
        label {
            margin: 10px 0 0 0;
            .checkbox01-input {
                display: none;
            }
            .checkbox01-parts{
                padding: 10px 0 10px 55px;
                position:relative;
                margin-right: 20px;
                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 35px;
                    height: 35px;
                    border: 1px solid $bgclr05;
                    background-color: $bgclr05;
                }
            }
            .checkbox01-input:checked + .checkbox01-parts::after{
              content: "";
              display: block;
              position: absolute;
              top: 8px;
              left: 14px;
              width: 7px;
              height: 14px;
              transform: rotate(40deg);
              border-bottom: 2px solid $bgclr04;
              border-right: 2px solid $bgclr04;
            }
        }
    }

    .imgWrap {
        text-align: center;
        margin: 20px 0 40px 0;
    }

    .tableType02 {
        margin: 0 0 60px 0;
        tr {
            th {
                width: 33%;
                font-weight: bold;
                position: relative;
                color: $clrBlck;
                span {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%) translateX(-50%);
                    padding: 0 7px;
                    background: $clrRed02;
                    color: $clrWht;
                    font-size: 10px;
                    font-weight: bold;
                }
            }
            td {
                width: 67%;
                padding: 30px 0;
            }
            &:first-child {
                border-top: 1px solid $clrBorder02;
            }
        }
    }
    .submitWrap {
        text-align: center;
        input[type=submit] {
            width: 450px;
            padding: 15px 0;
            background: $bgclr01;
            background: $bgclr01_ie;
            color: $clrWht;
            transition: 0.5s ease;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.p-contactFlowWrap {
    position: relative;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin: 55px auto 35px;
    @media #{$querySp} {
        width: 100%;
    }
    @media #{$queryPc} {
        width: 260px;
    }
    &:before {
        content:"";
        height: 1px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        background-color: #e3e3e3;
        @media #{$querySp} {
            width: 95%;
            top: -17px;
        }
        @media #{$queryPc} {
            width: 224px;
            top: -17px;
        }
    }
}

.p-contactFlowItem {
    position: relative;
    @media #{$querySp} {

    }
    @media #{$queryPc} {
        font-size: 1.1rem;
        color: #999;
    }
    &:before {
        @include borderRadius(50%);
        content: "";
        background-color: #fff;
        border: 1px solid #3ca480;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        @media #{$querySp} {
            width: 16px;
            height: 16px;
            top: -25px;
        }
        @media #{$queryPc} {
            width: 16px;
            height: 16px;
            top: -25px;
        }
    }
}

.p-contactFlowItem--active {
    color: $clrBlck;
    &:before {
        @include gradient(#7cc28e, #3ca480, horizontal);
        border: none;
    }
}

@media #{$querySp} {
    #contact {
        &.contentWrap {
            padding: 0 0 32px 0;
        }
        .main-visual {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right;
        }
    }
    .contactContent {
        padding: 50px 0;
        .inner {
            width: 100%;
            padding: 0 15px;
        }
        p {
            line-height: 2em;
        }
        h2 {
            font-size: 15px;
            margin: 0 0 10px 0;
            span {
                width: 40px;
                height: 3px;
                margin: 10px 0 0 0;
            }
        }
        .textWrap {
            font-size: 16px;
            margin: 0 0 40px 0;
        }
        .borderWrap {
            margin: 0 0 32px 0;
            &.type_01{
                padding: 35px 0;
            }
            &.type_02{
                padding: 20px 20px;
                .text{
                    margin: 0 0 25px 0;
                }
            }
            .ttl {
                font-size: 14px;
                font-weight: bold;
                padding: 0 10px;
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateY(-50%) translateX(-50%);
                background-color: $clrWht;
                line-height: 1.2em;
            }
            .number {
                font-size: 35px;
                margin: 0 0 10px 0;
            }
            label {
                margin: 10px 0 0 0;
                .checkbox01-input {
                    display: none;
                }
                .checkbox01-parts{
                    padding: 2px 0 2px 25px;
                    position:relative;
                    margin-right: 20px;
                    font-size: 13px;
                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 16px;
                        height: 16px;
                        border: 1px solid $bgclr05;
                        background-color: $bgclr05;
                    }
                }
                .checkbox01-input:checked + .checkbox01-parts::after{
                  content: "";
                  display: block;
                  position: absolute;
                  top: 0px;
                  left: 5px;
                  width: 7px;
                  height: 14px;
                  transform: rotate(40deg);
                  border-bottom: 2px solid $bgclr04;
                  border-right: 2px solid $bgclr04;
                }
            }
        }

        .imgWrap {
            text-align: center;
            margin: 32px 0 32px 0;
        }

        .tableType02 {
            width: 100%;
            margin: 0 0 30px 0;
            tr {
                th {
                    display: block;
                    width: 100%;
                    font-weight: bold;
                    position: relative;
                    border-bottom: 0px solid #ddd;
                    padding: 7px 17px;
                    span {
                        font-size: 10px;
                        padding: 2px 10px;
                    }
                }
                td {
                    display: block;
                    width: 100%;
                    padding: 0 17px 17px 17px;
                }
            }
        }
        .submitWrap {
            text-align: center;
            input[type=submit] {
                width: 100%;
                padding: 15px 0;
                background: $bgclr01;
                color: $clrWht;
            }
        }
    }
}










/*  180409_菊地追加
______________________________________________*/
.contactContent .submitWrap--check {
    display: flex;
    justify-content: center;
    form {
        input[type=submit] {
            @media #{$querySp} {
                width: 50%;
            }
            @media #{$queryPc} {
                width: 300px;
                margin: 0 10px;
            }
        }
        &:first-child {
            input[type=submit] {
                @media #{$querySp} {
                   margin-right: 5px;
                }
                @media #{$queryPc} {
                }
            }
        }
        &:last-child {
            input[type=submit] {
                @include gradient(#c2bb7c, #a49e3c, horizontal);
                @media #{$querySp} {
                   margin-left: 5px;
                }
                @media #{$queryPc} {
                }
            }
        }
    }
}

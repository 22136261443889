@charset "UTF-8";
/*----------------------------------------------------------------
ページャーのコンポーネント
-----------------------------------------------------------------*/
.pagerWrap {
    margin: 50px 0 0;
    ul {
        @include clearfix;
        display: table;
        margin: 0 auto;
        li {
            float: left;
            background: $clrWht;
            margin: 0 20px 0 0;

            &:last-child{
                margin: 0;
            }
            &.active {
                background: $bgclr01;
                a {
                    color: $clrWht;
                    border: 1px solid $bgclr01;
                }
            }
            a {
                display: block;
                padding: 10px 19px;
                border: 1px solid $clrBorder04;
                box-sizing: border-box;
                &:hover {
                    opacity: 0.8;
                }

            }
        }
    }
}


@media #{$querySp} {
    .pagerWrap {
        width: 95%;
        margin: 25px auto;
        ul {
            li {
                margin: 0 10px 0 0;

                &:last-child{
                    margin: 0;
                }
                a {
                    padding: 5px 10px;
                }
            }
        }
    }
}

@charset "UTF-8";
/* ----------------------------------------------------------------
テーブルのコンポーネント
-----------------------------------------------------------------*/

.tableType01 {
    width: 100%;
    tr {
        th {
            vertical-align: middle;
            text-align: center;
            background-color: $clrTable01;
            border-top: 1px solid $clrBorder02;
            border-left: 1px solid $clrBorder02;
            border-right: 1px solid $clrBorder02;
            border-bottom: 1px solid $clrBorder02;
            box-sizing: border-box;
            padding: 22px;
            color: $clrBlck;
        }
        td {
            text-align: left;
            border-top: 1px solid $clrBorder02;
            border-left: 1px solid $clrBorder02;
            border-right: 1px solid $clrBorder02;
            border-bottom: 1px solid $clrBorder02;
            box-sizing: border-box;
            padding: 22px;
        }
    }
}
.tableType02 {
    width: 100%;
    background: $bgclr07;
    tr {
        th {
            vertical-align: middle;
            text-align: left;
            border-bottom:1px solid $clrBorder02;
            box-sizing: border-box;
            padding: 15px 20px;
        }
        td {
            vertical-align: middle;
            text-align: left;
            border-bottom: 1px solid $clrBorder02;
            box-sizing: border-box;
            padding: 30px;
            input[type=text],
            input[type=email],
            input[type=tel]{
                width: 510px;
                height: 50px;
                border: 1px solid $clrBorder02;
                border-radius: 5px;
                padding: 10px;
            }
            textarea {
                width: 510px;
                height: 240px;
                border: 1px solid $clrBorder02;
                border-radius: 5px;
                padding: 10px;
            }
        }
    }
}
@media #{$querySp} {
    .tableType01 {
        tr {
            th {
            }
            td {
                border-top: 0px solid $clrBorder02;
                border-left: 1px solid $clrBorder02;
                border-right: 1px solid $clrBorder02;
                border-bottom: 0px solid $clrBorder02;
            }
            &:last-child {
                td {
                    border-bottom: 1px solid $clrBorder02;
                }
            }
        }
    }
    .tableType02 {
        width: 100%;
        tr {
            th {
                vertical-align: middle;
                text-align: left;
                border-bottom:1px solid $clrBorder02;
                box-sizing: border-box;
                padding: 15px 20px;
            }
            td {
                input[type=text],
                input[type=email],
                input[type=tel]{
                    width: 100%;
                    height: 30px;
                    border: 1px solid $clrBorder02;
                    border-radius: 5px;
                    padding: 5px;
                    background-color: $clrWht;
                }
                textarea {
                    width: 100%;
                    height: 176px;
                    border: 1px solid $clrBorder02;
                    border-radius: 5px;
                    padding: 5px;
                }
            }
        }
    }

}

@charset "UTF-8";
/* ----------------------------------------------------------------
serviceページのみのスタイル
-----------------------------------------------------------------*/
#service {
    .main-visual {
        background: url("../images/service_main.jpg");
        background-size: cover;
    }
    .sec01,
    .sec02,
    .contentBox {
        opacity: 0;
    }
    .wrap {
        background-color: $clrWht;
        padding: 100px 0;
        line-height: 2em;
        position: relative;
        &:before {
            content: "";
            top: 0;
            left: 0;
            position: absolute;
            width: 100%;
            height: 4px;
            background: $bgclr01;
            background: $bgclr01_ie;
        }
        .inner {
            width: 850px;
            margin: 0 auto;
        }
        .inner_service {
            width: 1000px;
            margin: 0 auto;
            .imgWrap {
                margin: 0 0 70px 0;
            }
            .contentBox {
                @include clearfix;
                margin: 0 0 100px 0;
                .fl_l {
                    float: left;
                    padding: 0 60px 0 40px;
                }
                .fl_r {
                    float: right;
                }
                .textWrap{
                    padding: 20px 40px;
                }
                &.img_l {
                    &.type_02 {
                        .fl_l {
                            margin: 90px 0 0 0;
                            padding: 0 0 0 85px;
                        }
                        .imgWrap {
                            margin: 0 0 0 -70px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                    .fl_l {
                        float: right;
                        width: 60%;
                    }
                    .fl_r {
                        float: left;
                        width: 40%;
                    }
                }
                &.img_r {
                    &.type_01 {
                        .imgWrap {
                            margin: 30px 0 0 -8px;
                        }
                    }
                    &.type_03 {
                        margin: 0 0 0 0;
                        .imgWrap {
                            margin: 30px 0 0 -8px;
                        }
                        .textWrap{
                            margin: 0;
                        }
                    }
                    &.type_04 {
                        .fl_l {
                            .imgWrap {
                                text-align: center;
                                margin: 0;
                            }
                            .textWrap {
                                padding: 0px 35px;
                                ul {
                                    li {
                                        padding-left: 2.5em;
                                        text-indent: -1em;
                                        span {
                                            font-size: 12px;
                                        }
                                    }
                                }
                            }
                        }
                        .fl_r {
                            .imgWrap {
                                margin: 0 0 0 -8px;
                            }
                        }

                    }
                    .fl_l {
                        width: 60%;
                    }
                    .fl_r {
                        width: 40%;
                    }
                }
                .block_col_3 {
                    @include clearfix;
                    padding: 0 50px 0;
                    .imgWrap {
                        font-weight: bold;
                        color: $clrBlck;
                        float: left;
                        width: 30%;
                        margin: 0 5% 0 0;
                        &:last-child{
                            margin:0;
                        }
                        img {
                            margin: 0 0 15px 0;
                        }
                    }
                }
                &.mb0 {
                    margin-bottom: 0;
                }
            }
        }

        h2 {
            margin: 0px 0 45px;
            text-align: left;
            span {
                font-size: 16px;
                color: $clrBlck;
                font-weight: 600;
                margin: 10px 0 0 0;
                display: inline-block;
            }
        }
        h3 {
            font-size: 24px;
            position: relative;
            margin: 0 0 30px 0;
            padding: 0 0 20px 0;
            &:after {
                position: absolute;
                bottom: 0px;
                left:0;
                content:"";
                width: 240px;
                height: 3px;
                border-bottom: 3px solid $clrBorder03;
            }
        }
        .textWrap {
            margin: 0 0 30px 0;
            padding: 0 0 10px 0;
        }
        .imgWrap {
            margin: 0 0 35px 0;
        }
    }
}

@media #{$querySp} {
    #service {
        .main-visual {
            background-repeat: no-repeat;
            background-size: cover;
        }
        .wrap {
            background-color: $clrWht;
            padding: 40px 0;
            line-height: 2em;
            &:before {
                height: 3px;
            }
            .inner {
                width: 100%;
                margin: 0 auto;
                padding: 0 15px;
            }
            .inner_service {
                width: 90%;
                margin: 0 auto;
                .contentBox {
                    @include clearfix;
                    margin: 0 0 40px 0;
                    .fl_l {
                        padding: 0;
                    }
                    .fl_r {
                    }
                    .textWrap{
                        padding: 0;
                    }
                    &.img_l {
                        &.type_02 {
                            .fl_l {
                                width: 100%;
                                margin: 0;
                                padding: 0;
                            }
                            .fl_r {
                                width: 70%;
                            }
                            .imgWrap {
                                margin: 0 0 0 -27px;
                            }
                        }
                    }
                    &.img_r {
                        &.type_01 {
                            .fl_l {
                                width: 100%;
                            }
                            .fl_r {
                                width: 70%;
                            }
                            .imgWrap {
                                margin: 0 0 0 27px;
                            }
                        }
                        &.type_03 {
                            margin: 0 0 0 0;
                            .fl_l {
                                width: 100%;
                            }
                            .fl_r {
                                width: 70%;
                            }
                            .imgWrap {
                                margin: 0 0 0 27px;
                            }
                            .textWrap {
                                margin: 0 0 35px 0;
                            }
                        }
                        &.type_04 {
                            .fl_l {
                                width: 100%;
                            }
                            .fl_r {
                                width: 70%;
                                .imgWrap {
                                    margin: 0 0 0 27px;
                                }
                            }
                        }
                    }
                    .block_col_3 {
                        @include clearfix;
                        padding: 0;
                        .imgWrap {
                            float: left;
                            width: 100%;
                            margin: 0  0 35px 0;
                            &:last-child{
                                margin:0;
                            }
                            img {
                                margin: 0 0 8px 0;
                            }
                        }
                    }
                    &.mb0 {
                        margin-bottom: 0;
                    }
                }
            }

            &.sec01 {
                h2 {
                    img {
                        width: 169px;
                    }
                }
            }
            &.sec02 {
                h2 {
                    img {
                        width: 240px;
                    }
                }
            }
            h2 {
                margin: 0px 0 30px;
                span {
                    font-size: 13px;
                    margin: 5px 0 0 0;
                }
            }
            h3 {
                font-size: 14px;
                margin: 0 0 15px 0;
                padding: 0 0 10px 0;
                &:after {
                    width: 130px;

                }
            }
            .textWrap {
                margin: 0 0 15px 0;
                padding: 0 0 5px 0;
            }
            .imgWrap {
                margin: 0 0 17px 0;
            }
        }
    }

}

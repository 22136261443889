@charset "UTF-8";
/*----------------------------------------------------------------
見出しのコンポーネント
-----------------------------------------------------------------*/
.c-headline01,
.c-headline02,
.c-headline03,
.c-headline04,
.c-headline05,
.c-headline06 {
	color: $clrBlck;
	small {
	}
}

.c-headline01 {
	@media #{$querySp} {
		margin-bottom: 24px;
		font-size: 1.9rem;
		font-weight: 600;
		text-align: center;
	}
	@media #{$queryPc} {
		margin-bottom: 56px;
		font-size: 3.6rem;
		line-height: 1.5;
	}
}
@charset "UTF-8";
/* ----------------------------------------------------------------
detailページのみのスタイル
-----------------------------------------------------------------*/
.detailContent {
    opacity: 0;
    background-color: $clrWht;
    padding: 80px 0;
    .inner {
        width: 850px;
        margin: 0 auto;
    }
    h2 {
        font-size: 24px;
        margin: 95px 0 65px;
        text-align: center;
    }
    .imgWrap {
        margin: 0 0 35px 0;
    }
}

.detailLinkWrap {
    opacity: 0;
    @include clearfix;
    a {
        display: block;
    }
    &.type_01 {
        margin: -100px 0 120px 0;
    }
    &.type_02 {
        padding: 0 0 0 0;
    }
    .fl_l {
        float: left;
    }
    .fl_r {
        float: right;
    }
}

#detail {
    .main-visual {
        background: url("../images/topics_main_01.jpg");
        background-size: cover;
    }
    .detailTtlWrap {
        opacity: 0;
        color: $clrBlck;
        .inner {
            width: 800px;
            text-align: center;
            padding: 60px 0;
            background: $bgclr06;
            margin: -80px auto 0;
            z-index: 10;
            position: relative;
        }
        .date {
            font-size: 16px;
        }
        h2 {
            font-size: 24px;
        }
    }
}

@media #{$querySp} {
    .detailContent {
        background-color: $clrWht;
        padding: 0;
        .inner {
            width: 100%;
            margin: 0 auto;
            padding: 15px;
        }
        h2 {
            font-size: 24px;
            margin: 95px 0 65px;
            text-align: center;
        }
        .imgWrap {
            margin: 0 0 20px 0;
        }
    }

    .detailLinkWrap {
        @include clearfix;
        a {
            display: block;
            img {
                width: 50%;
            }
        }
        &.type_01 {
            margin: 0;
        }
        &.type_02 {
            padding: 0 0 10px 0;
        }
        .fl_l {
            float: left;
        }
        .fl_r {
            float: right;
            img {
                float: right;
            }
        }
    }

    #detail {
        &.contentWrap {
            padding: 0;
        }
        .main-visual {
            background-repeat: no-repeat;
            background-size: cover;
        }
        .detailTtlWrap {
            //position: relative;
            .inner {
                // position: absolute;
                // top: -200px;
                // left: 9%;
                width: 80%;
                // text-align: center;
                margin: -28px auto 0;
                padding: 25px 20px;
            }
            .date {
                font-size: 14px;
            }
            h2 {
                font-size: 16px;
            }
        }
    }
}

@charset "UTF-8";
/*----------------------------------------------------------------
ボタンのコンポーネント
-----------------------------------------------------------------*/
.btnWrap {
    a.btn_01 {
        display: block;
        text-align: center;
        width: 450px;
        margin: 0 auto;
        padding: 11px 0;
        background: $bgclr01;
        background: $bgclr01_ie;
        color: $clrWht;
        font-weight: bold;
        transition: 0.5s ease;
    }
}

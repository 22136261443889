@charset "UTF-8";
/*----------------------------------------------------------------
パンくずのコンポーネント
-----------------------------------------------------------------*/
.breadcrumb {
    font-size: 11px;
    background-color: $bgclr02;
    @media #{$querySp} {
        display: none;
    }
    ul {
        @include clearfix;
        li {
            float: left;
            position: relative;
            padding: 29px 15px 29px 0;
        }
        li:not(:first-child) {
            padding: 29px 15px 29px;
            &:after {
                content:"";
                position: absolute;
                top: 46%;
                left: -4px;
                transform: translateY(-50%) translateX(-50%);
                width: 6px;
                height: 6px;
                border: 1px solid;
                border-color: #a5a5a5 #a5a5a5 transparent transparent;
                transform: rotate(45deg);
            }
        }
    }
}

.c-breadcrumb {
	@media #{$querySp} {
		display: none;
	}
	width: 100%;
	background-color: $clrAccent;
}

.c-breadcrumbBody {
	width: 1200px;
	margin: 0 auto;
	padding: 15px 0;
	letter-spacing: -1em;
}

.c-breadcrumb__item {
	display: inline-block;
	position: relative;
	margin-right: 30px;
	font-size: 1.2rem;
	letter-spacing: 0;
	&:after {
		content: "/";
		position: absolute;
		top: 0;
		right: -18px;
		color: #fff;
	}
	a {
		color: #fff;
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

//modifier
.c-breadcrumb__item--home {

}

.c-breadcrumb__item--active {
	&:after {
		display: none;
		margin-right: 0;
	}
	a {
		color: $clrMain;
		text-decoration: none;
	}
}

@charset "UTF-8";
/* ----------------------------------------------------------------
utility
-----------------------------------------------------------------*/
/* clearfix
---------------------------------------------------- */
.u-clearfix {
    min-height: 0.1px!important;
    &:after {
    	content: "."!important;
	    display: block!important;
	    clear: both!important;
	    height: 0!important;
	    visibility: hidden!important;
    }
}

/* float
______________________________________________*/
.u-fRight {
	float: right;
}
.u-fLeft {
	float: left;
}

/* align
______________________________________________*/
.u-aLeft { text-align: left !important; }
.u-aCenter { text-align: center !important; }
.u-aRight { text-align: right !important; }
@media #{$queryPc} {
	.u-aLeft--pc { text-align: left !important; }
	.u-aCenter--pc { text-align: center !important; }
	.u-aRight--pc { text-align: right !important; }
}
@media #{$querySp} {
	.u-aLeft--sp { text-align: left !important; }
	.u-aCenter--sp { text-align: center !important; }
	.u-aRight--sp { text-align: right !important; }
}

/* hidden
______________________________________________*/
.u-hidden {
	display: none!important;
}

.u-hidden--sp {
	@media #{$querySp} {
		display: none!important;
	}
}

.u-hidden--pc {
	@media #{$queryPc} {
		display: none!important;
	}
}

/* img
______________________________________________*/
.u-imgfull {
	width: 100%!important;
	height: auto!important;
}

.u-imgfull--sp {
	@media #{$querySp} {
		width: 100%!important;
		height: auto!important;
	}
}

/* opacity
______________________________________________*/
.u-hover--opacity {
	&:hover {
		@include opacity(0.8)
	    @include trans($duration: .2s,$delay: 0s)
	}
}

/* color
______________________________________________*/
.u-clrWht {
	color: $clrWht !important;
}
.u-clrBlck {
	color: $clrBlck !important;
}
.u-clrGry {
	color: $clrGry !important;
}
.u-clrBase {
	color: $clrBase!important;
}
.u-clrMain {
	color: $clrMain!important;
}
.u-clrAccent {
	color: $clrAccent!important;
}
.u-clrPjc01 {
	color: $clrProject01!important;
}
.u-clrPjc02 {
	color: $clrProject02!important;
}
.u-clrPjc03 {
	color: $clrProject03!important;
}

/* font Weight
______________________________________________*/
.u-fwNml {
	font-weight: normal!important;
}
.u-fwBold {
	font-weight: bold!important;
}

/* fontsize
______________________________________________*/
$fontStride: 100;

@while $fontStride > -1 {
	.u-fs#{$fontStride} {
		font-size: #{$fontStride}px !important;
	}
	@media #{$queryPc} {
		.u-fs#{$fontStride}--pc {
			font-size: #{$fontStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-fs#{$fontStride}--sp {
			font-size: #{$fontStride}px !important;
		}
	}
	$fontStride: $fontStride - 1;
}

/* width
______________________________________________*/
$widthStride: 1000;

@while $widthStride > -1 {
	.u-wid#{$widthStride} {
		width: #{$widthStride}px !important;
	}
	@media #{$queryPc} {
		.u-wid#{$widthStride}--pc {
			width: #{$widthStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-wid#{$widthStride}--sp {
			width: #{$widthStride}px !important;
		}
	}
	$widthStride: $widthStride - 1;
}

/* margin
______________________________________________*/
$marginStride: 100;

@while $marginStride > -1 {
	.u-mt#{$marginStride} {
		margin-top: #{$marginStride}px !important;
	}
	.u-mb#{$marginStride} {
		margin-bottom: #{$marginStride}px !important;
	}
	.u-mr#{$marginStride} {
		margin-right: #{$marginStride}px !important;
	}
	.u-ml#{$marginStride} {
		margin-left: #{$marginStride}px !important;
	}
	.u-m#{$marginStride} {
		margin: #{$marginStride}px !important;
	}
	@media #{$queryPc} {
		.u-mt#{$marginStride}--pc {
			margin-top: #{$marginStride}px !important;
		}
		.u-mb#{$marginStride}--pc {
			margin-bottom: #{$marginStride}px !important;
		}
		.u-mr#{$marginStride}--pc {
			margin-right: #{$marginStride}px !important;
		}
		.u-ml#{$marginStride}--pc {
			margin-left: #{$marginStride}px !important;
		}
		.u-m#{$marginStride}--pc {
			margin: #{$marginStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-mt#{$marginStride}--sp {
			margin-top: #{$marginStride}px !important;
		}
		.u-mb#{$marginStride}--sp {
			margin-bottom: #{$marginStride}px !important;
		}
		.u-mr#{$marginStride}--sp {
			margin-right: #{$marginStride}px !important;
		}
		.u-ml#{$marginStride}--sp {
			margin-left: #{$marginStride}px !important;
		}
		.u-m#{$marginStride}--sp {
			margin: #{$marginStride}px !important;
		}
	}
	$marginStride: $marginStride - 1;
}

/* Padding
______________________________________________*/
$paddingStride: 100;
@while $paddingStride > -1 {
	.u-pt#{$paddingStride} {
		padding-top: #{$paddingStride}px !important;
	}
	.u-pb#{$paddingStride} {
		padding-bottom: #{$paddingStride}px !important;
	}
	.u-pr#{$paddingStride} {
		padding-right: #{$paddingStride}px !important;
	}
	.u-pl#{$paddingStride} {
		padding-left: #{$paddingStride}px !important;
	}
	.u-p#{$paddingStride} {
		padding: #{$paddingStride}px !important;
	}
	@media #{$queryPc} {
		.u-pt#{$paddingStride}--pc {
			padding-top: #{$paddingStride}px !important;
		}
		.u-pb#{$paddingStride}--pc {
			padding-bottom: #{$paddingStride}px !important;
		}
		.u-pr#{$paddingStride}--pc {
			padding-right: #{$paddingStride}px !important;
		}
		.u-pl#{$paddingStride}--pc {
			padding-left: #{$paddingStride}px !important;
		}
		.u-p#{$paddingStride}--pc {
			padding: #{$paddingStride}px !important;
		}
	}
	@media #{$querySp} {
		.u-pt#{$paddingStride}--sp {
			padding-top: #{$paddingStride}px !important;
		}
		.u-pb#{$paddingStride}--sp {
			padding-bottom: #{$paddingStride}px !important;
		}
		.u-pr#{$paddingStride}--sp {
			padding-right: #{$paddingStride}px !important;
		}
		.u-pl#{$paddingStride}--sp {
			padding-left: #{$paddingStride}px !important;
		}
		.u-p#{$paddingStride}--sp {
			padding: #{$paddingStride}px !important;
		}
	}
	$paddingStride: $paddingStride - 1;
}
@charset "UTF-8";
/* ----------------------------------------------------------------
mixin
-----------------------------------------------------------------*/
@mixin clearfix {
    min-height: 1px;
    &:after {
        content: "";
        display: block;
        clear: both;
        height: 0;
        visibility: hidden;
    }
}

// calc
@mixin calc($property, $expression) {
	#{$property}: -moz-calc(#{$expression});
	#{$property}: -o-calc(#{$expression});
	#{$property}: -webkit-calc(#{$expression});
	#{$property}: calc(#{$expression});
}

// box-shadow
@mixin boxShadow($blur,$alpha) {
	box-shadow: 0 2px $blur 0 rgba(0, 0, 0, $alpha);
}

// opacity
@mixin opacity($opacity) {
	opacity: $opacity;
	$opacityIE: $opacity * 100;
	filter: alpha(opacity=$opacityIE);
}

// border radius
@mixin borderRadius($size) {
	border-radius: $size;
}

// transition
@mixin trans($duration: .2s,$delay: 0s) {
	transition: all $duration ease-in-out $delay;
}

// transform
@mixin transform($transform) {
	transform: $transform;
}
// scale
@mixin scale($scale) {
	@include transform(scale($scale));
}
// rotate
@mixin rotate($rotate) {
	@include transform(rotate($rotate));
}

// placeholder
@mixin placeholdercolor($selection, $default, $focus) {
	&::selection,
	&::-moz-selection {
		color: $selection;
	}
	//&:placeholder-shown,
	&::-webkit-input-placeholder,
	&:-moz-placeholder,
	&::-moz-placeholder,
	&:-ms-input-placeholder {
		color: $default;
		opacity: 1;
	}
	&:focus::-webkit-input-placeholder,
	&:focus:-moz-placeholder,
	&:focus:-ms-input-placeholder {
		color: $focus;
	}
}

@mixin selectNone {
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}

//formreset
@mixin formreset {
	background-image: none;
	background-color: none;
	border: none;
	border-radius: 0;
	outline: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

//btn
@mixin btnAccent($clr , $darken) {
	background: $clr;
	color: $clrLink;
	&:hover {
		background: darken($clr , $darken);
		// color: $clrWht;
	}
}

// placeholder
@mixin placeholder($clr) {
	&::-webkit-input-placeholder {
		color:$clr;
	}
	&::-moz-placeholder {
		color:$clr;
	}
	&:-ms-input-placeholder {
		color:$clr;
	}
}
// gradation
@mixin gradient($start-color, $end-color, $orientation) {
    background: $start-color;
    @if $orientation == vertical {
        // vertical
    background: -moz-linear-gradient(top,  $start-color 0%, $end-color 100%);
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,$start-color), color-stop(100%,$end-color));
    background: -webkit-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -o-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: -ms-linear-gradient(top,  $start-color 0%,$end-color 100%);
    background: linear-gradient(to bottom,  $start-color 0%,$end-color 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=0 );
    }
 @else if $orientation == horizontal
 {
 // horizontal
 background: -moz-linear-gradient(left,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(linear, left top, right top, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: -o-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: -ms-linear-gradient(left,  $start-color 0%,$end-color 100%);
 background: linear-gradient(to right,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
 }
 @else
 {
 // radial
 background: -moz-radial-gradient(center, ellipse cover,  $start-color 0%, $end-color 100%);
 background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,$start-color), color-stop(100%,$end-color));
 background: -webkit-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: -o-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: -ms-radial-gradient(center, ellipse cover,  $start-color 0%,$end-color 100%);
 background: radial-gradient(ellipse at center,  $start-color 0%,$end-color 100%);
 filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$start-color', endColorstr='$end-color',GradientType=1 );
 }
 }

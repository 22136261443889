@charset "UTF-8";
/* ----------------------------------------------------------------
footerのスタイル
-----------------------------------------------------------------*/
footer {
    background-color: $bgclr02;
    .inner {
        @include clearfix;
        width: 1140px;
        margin: 0 auto;
        padding: 75px 0;
    }
    .fl_l {
        width: 55%;
        float: left;
        position: relative;
        height: 84px;
        .copy {
            position: absolute;
            bottom: 0;
            color: $clrBlck02;
            .text {
                margin: 20px 0 0 0;
            }
        }
    }
    .fl_r {
        width: 45%;
        float: right;
        .footerlistWrap {
            display: flex;
            .footer_list {
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    ul {
        li {
            // justify-content: space-between;
            a {
                font-size: 12px;
                color: $clrGry05;
            }
        }
        li:nth-child(1),
        li:nth-child(4),
        li:nth-child(7){
            width: 150px;
        }
        li:nth-child(2),
        li:nth-child(5),
        li:nth-child(8){
            width: 250px;
        }
        li:nth-child(3),
        li:nth-child(6),
        li:nth-child(9){
            width: 100px;
        }
    }
    .copy_sp {
        display: none;
    }
}
@media #{$querySp} {
    footer {
        .inner {
            width: 85%;
            padding: 17px 0 0 0;
        }
        .fl_l {
            width: 100%;
            .logo {
                display: none;
            }
            .copy {
                width: 100%;
                .text{
                    color: $clrGry03;
                    display: block;
                    text-align: center;
                    border-top: 1px solid $clrGry02;
                    margin: 17.5px 0 0 0 ;
                    padding: 17.5px 0;
                    width: 100%;
                }
            }
        }
        .fl_r {
            width: 100%;
        }
        ul {
            li {
                a {
                    line-height: 2em;
                }
            }
            li:nth-child(1),
            li:nth-child(2),
            li:nth-child(3),
            li:nth-child(4),
            li:nth-child(5),
            li:nth-child(6),
            li:nth-child(7),
            li:nth-child(8),
            li:nth-child(9){
                width: 100%;
            }
        }
        .copy_sp {
            color: $clrGry03;
            display: block;
            text-align: center;
            border-top: 1px solid $clrGry02;
            margin: 17.5px 0 0 0 ;
            padding: 17.5px 0;
        }
    }
}

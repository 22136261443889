@charset "UTF-8";
/* ----------------------------------------------------------------
headerのスタイル
-----------------------------------------------------------------*/

header {
    position: relative;
    .logo {
        position: absolute;
        top: 30px;
        left: 20px;
    }
    nav {
        @include clearfix;
    }
    a {
        &:hover {
            opacity: 1;
        }
    }
    ul.nav {
        @include clearfix;
        // width: 50%;
        float: right;
        li {
            float: left;
            margin: 0 64px 0 0;
            text-align: center;
            a {
                display: inline-block;
                padding: 31px 0;
                font-weight: bold;
                &:hover {
                    color: $clrLightGreen;
                }
            }
            &:last-child {
                margin: 0;
                &:hover {
                    opacity: 0.8;
                    transition: 0.5s ease;
                }
                a {
                    width:100%;
                }
            }
        }
        li:last-child {
            width: 230px;
            background: $bgclr01;
            background: $bgclr01_ie;
            a {
                color: $clrWht;
            }
        }
    }
    .sp_g_menu_wrap {
        @include clearfix;
        .sp_g_menu {
            float: right;
            width: 60px;
            height: 40px;
            padding: 15px 0;
            position: absolute;
            top: 5px;
            right: 0;
            span {
                width: 20px;
                height: 1px;
                margin: 0 auto 9px auto;
                background-color: $clrLightGreen;
                display: block;
                -webkit-transition: .2s;
                -moz-transition: .2s;
                -o-transition: .2s;
                -ms-transition: .2s;
                /* transition: .2s; */
                &:last-child {
                    margin-bottom: 0;
                }
            }
            &:last-child {
                span {
                    margin: 0 auto 5px;
                }
            }
            &.active {
                span.top {
                    margin-top: 8px;
                    transform: rotate(-45deg);
                }
                span.middle {
                    display: none;
                }
                span.bottom {
                    margin-top: -6px;
                    transform: rotate(45deg);
                }
            }
        }
    }
}

@media #{$querySp} {
    header {
        width: 100%;
        height: 56px;
        position: fixed;
        top: 0;
        z-index: 9999;
        background-color: #FFF;
        &.active {
        }
        .logo {
            position: absolute;
            top: 18px;
            left: 20px;
            img {
                width: 146px;
            }
        }
        ul.nav {
            @include clearfix;
            width: 100%;
            margin: 56px 0 0 0;
            z-index: 10;
            opacity: 0;
            max-height: 0;
            overflow-y: hidden;
            -webkit-transition: all 1s;
            -moz-transition: all 1s;
            -ms-transition: all 1s;
            -o-transition: all 1s;
            transition: all 1s;
                &.active {
                    opacity: 1;
                    max-height: 100%;
                }
            li {
                float: left;
                width: 100%;
                border-bottom: 1px solid $clrBorder02;
                background: $clrWht;
                a {
                    display: block;
                    padding: 15px 0;
                }
                &:first-child {
                    border-top: 1px solid $clrBorder02;
                }
            }
            li:last-child {
                width: 100%;
                background: $bgclr01;
                a {
                    color: $clrWht;
                }
            }
        }
    }
}

@charset "UTF-8";
/* ----------------------------------------------------------------
topicsページのみのスタイル
-----------------------------------------------------------------*/
#topics {
    .main-visual {
        background: url("../images/topics_main_02.jpg");
        background-size: cover;
    }
    .topicsBox {
        opacity: 0;
    }
}
@media #{$querySp} {
    #topics {
        .main-visual {
            background-repeat: no-repeat;
            background-size: cover;
        }
        .topicsContent {
            .inner {
                padding: 0;
                .topicsBox {
                    .imgWrap {
                        height: 90px;
                        img {
                            max-height: 90px;
                        }
                    }

                }
            }
        }
    }
}

@charset "UTF-8";
/*----------------------------------------------------------------
レイアウトのコンポーネント
-----------------------------------------------------------------*/
.c-wrap__all {
	width: 100%;
	box-sizing: border-box;
	@media #{$queryPcTab} {
		min-width: $containerWrap;
	}
}

.c-wrap__container {
	width: 100%;
	max-width: $containerWidthBase;
	margin: 0 auto;
	box-sizing: boder-box;
}

.c-wrap__container--home {
	max-width: 100%;
}

//2カラムレイアウト
.c-wrap__container--2col {
	display: table;
	table-layout: fixed;
}

.c-container__left {
	width: 100%;
	box-sizing: border-box;
	@media #{$querySp} {
		display: block;
		padding: 20px;
	}
	@media #{$queryPc} {
		display: table-cell;
		vertical-align: top;
		padding: 88px 60px 100px 0;
	}
}

.c-container__right {
	box-sizing: border-box;
	@media #{$querySp} {
		display: inline-block;
	}
	@media #{$queryPc} {
		display: table-cell;
		vertical-align: top;
		width: $innerCol2sub;
	}
}

//modifier
.c-inner--sm {
	max-width: $containerWidthSmall;
}

.c-inner--lg {
	max-width: $containerWidthLarge;
}
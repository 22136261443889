@charset "UTF-8";
/* ----------------------------------------------------------------
変数の設定項目
-----------------------------------------------------------------*/

/**
 * 1:パスの設定
 * 2:font-familyの設定
 * 3:font-size・line-heightの設定
 * 4:colorの設定
 * 5:マージンの設定
 * 6:componentパーツ「.c-grid」の余白の設定
 * 7:componentパーツ「.c-container, .c-inner」の幅設定
 * 8:ブレイクポイントの設定
 * 9:フォームの設定
 * 適用範囲等の詳しい説明は下記URLからご確認ください。
 * http://
 */

/* 1:パスの設定
-----------------------------------------------------------------*/
$src: "../";


/* 2:font-familyの設定
-----------------------------------------------------------------*/
/* サイト全体のfont-familyの設定 */
$fontJpYuGo: '游ゴシック', 'Yu Gothic', '游ゴシック体', 'YuGothic', 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

/* イレギュラーなfont-familyの設定 */
$fontJpHiraGo: 'ヒラギノ角ゴシック', 'Hiragino Sans', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', 'Meiryo', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;

/* イレギュラーなfont-familyの設定 */
$fontJpHiraMin: 'ヒラギノ明朝 ProN W3', 'Hiragino Mincho ProN', 'HG明朝E', 'ＭＳ Ｐ明朝', 'ＭＳ 明朝', serif;

/* イレギュラーなfont-familyの設定 */
$fontJpMeiryo: 'メイリオ', 'Meiryo', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;



/* 3:font-size・line-heightの設定
-----------------------------------------------------------------*/
 /* フォントサイズの設定【単位:rem】 */
$fsBase: 1.4; //base
$fsBaseSp: 1.2; //sp base

/* 行間の設定 */
$lhBase: 2; //base
$lhBaseSp: 1.5; //sp base


/* 4:colorの設定
-----------------------------------------------------------------*/
/* テキストのカラー設定 */
$clrTxtBlack: #000;
$clrTxtGray: #555555;

/* リンクのカラー設定 */
$clrLink: #000; //標準のリンク色
$clrLinkHover: #000; //hover時のリンク色

/* 一般的なiカラー */
$clrBlck: #000;
$clrBlck02: #454545;
$clrWht: #fff;
$clrGry: #555;
$clrGry02: #dcdcdc;
$clrGry03: #aca6a6;
$clrGry04: #fdfdfd;
$clrGry05: #767676;
$clrRed: #FF0000;
$clrRed02: #cb4042;
$clrLightGreen: #3fa581;
$bgclr01: linear-gradient(left, #7cc28e, #3ca480);
$bgclr01_ie: -ms-linear-gradient(left, #7cc28e, #3ca480);
$bgclr02: #fafafa;
$bgclr03: #d4d4d4;
$bgclr04: #7cc28e;
$bgclr05: #f4f4f4;
$bgclr06: rgba(255,255,255,0.5);
$bgclr07: #fdfdfd;

/* プロジェクトカラー設定 */
$clrBase: #ffffff; //ベースカラー
$clrMain: #8fc31f; //メインカラー
$clrAccent: #121b31; //アクセントカラー
$clrPrimary: #ffffff; //選択
$clrProject01: #fff; //その他
$clrProject02: #fff; //その他
$clrProject03: #fff; //その他

/* ボタンリンクのカラー設定 */
$clrLinkBtn: #fff; //ボタンの文字色
$clrLinkBtnHover: #fff; //hover時のボタンの文字色
$clrhover: 10%; //hoverした際の彩度の設定

/* ボーダーカラーの設定 */
$clrBorder: #e1e1e1; //tableのborderカラー
$clrBorder02: #dddddd;
$clrBorder03: #58b186;
$clrBorder04: #787878;
$clrBorder04: #dcdcdc;


/* table color */
$clrTable01: #f5f5f3;

/* arrow color */
$clrArrow01: #e5e5e5;
/* 5:マージンの設定
-----------------------------------------------------------------*/
$mgSmall: 0;
$mgNormal: 0;
$mgLarge: 0;
$mgContainer: 0;


/* 6:componentパーツ「.c-grid」の余白の設定
 * 詳しくは下記URLからご確認下さい。
 * http://
-----------------------------------------------------------------*/
/* PC */
$gridPaddingPc: 0px;//paddingの設定
$gridMarginRightPc: 40px;//margin-rightの設定
$gridMarginBottomPc: 40px;//margin-bottomの設定

/* TAB */
$gridPaddingTab: 0px;//paddingの設定
$gridMarginRightTab: 0px;//margin-rightの設定
$gridMarginBottomTab: 0px;//margin-bottomの設定

/* SP */
$gridPaddingSp: 0px;//paddingの設定
$gridMarginRightSp: 20px;//margin-rightの設定
$gridMarginBottomSp: 20px;//margin-bottomの設定


/* 7:componentパーツ「.c-container, .c-inner」の幅設定
-----------------------------------------------------------------*/
$containerWidthBase: 1200px;//ベース幅
$containerWidthSmall: 0px;//最小幅
$containerWidthLarge: 1200px;//最大幅
$containerWrap: 1200px;//タブレットでの表示サイズ

/* サブコンテンツの幅指定 */
$innerCol2sub: 275px;


/* 8:ブレイクポイントの設定
-----------------------------------------------------------------*/
$queryPc: "screen and (min-width: 768px)";//PC
$queryPcTab: "screen and (min-width:768px)";//PC&TAB
$queryTab: "screen and (max-width: 1159px)";//TAB
$querySp: "screen and (max-width: 767px)";//SP

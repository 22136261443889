@charset "UTF-8";
/* ----------------------------------------------------------------
generalのスタイル
-----------------------------------------------------------------*/
a {
    &:hover {
        opacity: 0.8;
    }
}
.pc_only {
    display: block;
}
.sp_only {
    display: none;
}
.t_indent {
    padding-left:2.5em;
    text-indent:-1em;
}
.contentWrap {
    background-color: $bgclr02;
    padding: 0 0 100px 0;
    .inner {
        width: 1140px;
        margin: 0 auto;
    }
}

.main-visual_inner {
    width: 1206px;
    margin: 0 auto;
    padding: 0px 0 0 33px;
}
.main-visual {
    margin: 0 0 40px 0;
    position: relative;
    width: 100%;
    height: 240px;
    .ttlWrap {
        position: absolute;
        top: 20%;
        top: 60px;
        left: -4%;
        opacity: 0;
        transform: translate(-100px,0);
        -webkit-transform: translate(-100px,0);
        transition-duration:1s;
        background: $bgclr01;
        background: $bgclr01_ie;
        width: 410px;
        height: 126px;
        padding: 30px 0 0 65px;
        span {
            font-size: 16px;
            color: $clrWht;
            font-weight: bold;
        }
        &.on{
            opacity: 1;
            transform: translate(0,0);
            -webkit-transform: translate(0,0);
        }
        h1 {
            line-height: 1em;
        }
    }
    .imgWrap {
        img {
            width:100%;
        }
    }
}

.topicsBox {
    float:left;
    width: 360px;
    margin: 0 30px 30px 0;
    min-height: 485px;
    &:nth-child(3n) {
        margin: 0 0 30px 0;
    }
    .detail {
        padding: 30px;
        background-color: $clrWht;
        .ttl {
            font-size: 16px;
            font-weight: bold;
            margin: 0 0 20px 0;
        }
        .text{
            font-size: 13px;
            margin: 0 0 20px 0;
            color: $clrBlck;
        }

    }
    .btnWrap {
        position: relative;
        &:after {
            position: absolute;
            content: url("../images/icon_03.png");
            width: 22px;
            height: 13px;
            top: -14px;
            left: 0;
            right: 90px;
            bottom: 0;
            margin: auto;
        }
        a {
            color: $bgclr04;
            font-weight: bold;
            &:hover {
                opacity: 0.8;
            }
        }
    }
}

.linkWrap {
    > div {
        @include clearfix;
    }
    a {
        display: block;
        height: 360px;
    }
    .fl_l {
        float: left;
        width: 50%;
        position: relative;
        background: url("../images/link_access.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 360px;
        transition: 0.5s ease;
        &:hover {
            opacity: 0.8;
        }
        .textWrap {
            width: 120px;
        }
    }
    .fl_r {
        float: right;
        width: 50%;
        position: relative;
        background: url("../images/link_contact.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 360px;
        transition: 0.5s ease;
        &:hover {
            opacity: 0.8;
        }
        .textWrap {
            width: 137px;
        }
    }
    .textWrap {
        position: absolute;
        color: $clrWht;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
        height: 50px;
    }
}

.pageTop {
    @include clearfix;
    background-color: $clrWht;
    > div {
        float: right;
        position: relative;
        &.arrow {
            background: $bgclr03;
            transition: background 0.5s ease;
            &:hover {
                background: $clrLightGreen;
            }
            img {
                width: 22px;
                height: 14px;
            }
        }
        &.facebook {
            transition: 0.5s ease;
            &:hover {
                opacity: 0.8;
            }
            img {
                width: 12px;
                height: 22px;
            }
        }
    }
    a {
        display: block;
        width: 70px;
        height: 70px;
        img {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            margin: auto;
        }
        &:hover {
            opacity: inherit;
        }
    }
}

@media #{$querySp} {
    .pc_only {
        display: none;
    }
    .sp_only {
        display: block;
    }
    .contentWrap {
        // margin: 57px 0 0 0;
        margin: 56px 0 0 0;
        padding: 0 0 50px 0;
        .inner {
            width: 100%;
            margin: 0 auto;
            padding: 15px 15px;
        }
        .imgWrap {
            width: 100%;
            img {
                width:100%;
            }
        }
    }
    .main-visual_inner {
        width: 100%;
        margin: 0 auto;
        padding: 0 0 0 15px;
    }
    .main-visual {
        margin: 0 0 15px;
        width: 100%;
        height: 120px;
        .ttlWrap {
            top: 20%;
            width: 182px;
            height: 70px;
            padding: 10px 10px 0 15px;
            left: -15px;
            span {
                font-size: 13px;
                font-weight: bold;
            }
            h1 {
                width: 100%;
                img {
                    // width: 100%;
                    width: 160px;
                }
            }
        }
        .imgWrap {
            width: 100%;
            img {
                width:100%;
            }
        }
    }

    .topicsBox {
        @include clearfix;
        width: 100%;
        margin: 0 0 10px 0;
        min-height: auto;
        &:nth-child(3n) {
            margin: 0 0 10px 0;
        }
        .imgWrap {
            float: left;
            width: 50%;
            max-width: 145px;
            max-height: 90px;
        }
        .detail {
            float: left;
            width: 50%;
            padding: 15px 15px;
            .ttl {
                font-size: 13px;
                margin: 0 0 10px 0;
            }
            .text{
                display: none;
            }
        }
        .btnWrap {
            a {
                position: relative;
                font-size: 10px;
                color: $bgclr04;
                &:hover {
                    opacity: 0.8;
                }
                &:after {
                    position: absolute;
                    content: url("../images/icon_sp_03.png");
                    width: 14px;
                    height: 7px;
                    top: -9px;
                    left: 80px;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                }
            }
            &:after {
                position: absolute;
                content: "";
                width: 0;
                height: 0;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
            }
        }
    }
    .linkWrap {
        > div {
            @include clearfix;
        }
        a {
            display: block;
            height: 165px;
        }
        .fl_l {
            width: 100%;
            height: 165px;
            background-size: cover;
            background-position: bottom;
            img {
                width: 79px;
            }
        }
        .fl_r {
            width: 100%;
            height: 165px;
            background-size: cover;
            background-position: bottom;
            img {
                width: 93px;
            }
        }
        .textWrap {
            font-size: 12px;
            img {
                margin: 0 auto 5px;
            }
        }
    }
    .pageTop {
        @include clearfix;
        background-color: $clrWht;
        > div {
            float: right;
            position: relative;
            &.arrow {
                background-color: $bgclr03;
                img {
                    width: 19px;
                    height: 12px;
                }
            }
            &.facebook {
                background-color: $clrWht;
                img {
                    width: 10px;
                    height: 19px;
                }
            }
        }
        .logo {
            float: left;
            width: 50%;
            line-height: 60px;
            text-align: left;
            margin: 0 0 0 30px;
            img {
                width: 146px;
            }
        }
        a {
            display: block;
            width: 60px;
            height: 60px;
            img {
                position: absolute;
            }
        }
    }
}

@charset "UTF-8";
/* ----------------------------------------------------------------
aboutページのみのスタイル
-----------------------------------------------------------------*/
#about {
    .main-visual {
        background: url("../images/about_main_01.jpg");
        background-size: cover;
    }
}
.content_01,
.content_02,
.content_03 {
    opacity: 0;
}
.aboutContent {
    background-color: $clrWht;
    padding: 95px 0 80px 0;
    position: relative;
    &:before {
        content: "";
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 4px;
        background: $bgclr01;
        background: $bgclr01_ie;
    }
    .inner {
        width: 850px;
        margin: 0 auto;
    }
    .tableWrap {
        margin: 0 0 95px 0;
        .tableType01 {
            th {
                width: 22%;
            }
            td {
                width: 78%;
            }
        }
        .t_indent {
            padding-left:2.5em;
            text-indent:-1em;
        }
    }
    h2 {
        font-size: 24px;
        margin: 0 0 50px;
        text-align: center;
        color: $clrBlck;
    }
}
.mapWrap {
    margin: 0 0 95px 0;
    iframe {
        border: 2px solid $clrBorder04 !important;
        box-sizing: border-box;
        margin: 0 0 20px 0;
    }
}
.companyWrap {
    .detail {
        @include clearfix;
        padding: 10px 20px;
        border-bottom: 1px solid $clrBorder02;
        &:first-child{
            border-top: 1px solid $clrBorder02;
        }
        .date {
            float: left;
            width: 20%;
            position: relative;
            span {
                font-weight: bold;
                font-size: 24px;
                .year,
                .month {
                    font-size: 18px;
                }
            }
            &:after {
                content:url("../images/icon_arrow_r_l.png");
                position: absolute;
                top:0;
                left:110px;
                right:0;
                bottom:0;
                margin: auto;
                width: 11px;
                height: 39px;
            }
        }
        .text{
            float: left;
            padding: 10px 0;
        }
    }
}

@media #{$querySp} {
    #about {
        .main-visual {
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .aboutContent {
        padding: 47px 0 40px 0;
        &:before {
            height: 3px;
        }
        .inner {
            width: 100%;
            padding: 0 15px
        }
        .tableWrap {
            margin: 0 0 47px 0;
            .tableType01 {
                th {
                    display:block;
                    width: 100%;
                    padding: 7.5px;
                    text-align: left;
                }
                td {
                    display:block;
                    width: 100%;
                    padding: 7.5px;
                    text-align: left;
                }
            }
        }

        h2 {
            font-size: 24px;
            margin: 0 0 25px;
            text-align: center;
        }
    }
    .mapWrap {
        margin: 0 0 47px 0;
        iframe {
            width: 100%;
            height: 320px;
            border: 2px solid $clrBorder04 !important;
            margin: 0 0 10px 0;
        }
    }
    .companyWrap {
        .detail {
            @include clearfix;
            padding: 5px 10px;
            .date {
                width: 100%;
                position: relative;
                color: $clrBlck;
                p {
                    font-weight: bold;
                    font-size: 18px;
                    span {
                        font-size: 14px;
                    }
                }
                &:after {
                    display:none;
                    content:"";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 0;
                    height: 0;
                }
            }
            .text{
                float: left;
                width: 100%;
                padding: 5px 0 5px 25px;
                position: relative;
                &:after {
                    content:"";
                    position: absolute;
                    top: 5px;
                    left: 9px;
                    right: 0;
                    bottom:0;
                    background:url("../images/icon_arrow_r_l_sp.png");
                    background-size: 5px 20px;
                    background-repeat: no-repeat;
                }
            }
        }
    }
}

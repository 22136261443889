@charset "UTF-8";
/*
===============================================================================
■ アイコン背景表示用CSSライブラリ
===============================================================================
*/

/*
--------------------------------------------------
アイコン表示位置１【ブロックの中央＆上下左右端】
--------------------------------------------------
*/

.c-ico--left,
.c-ico--right {
  display: block;
  background-repeat: no-repeat;
  background-size: 24px auto;
}

.c-ico--right {
  background-position: right center;
  padding-right: 30px;
  padding-left: 30px;
}

.c-ico--left {
  background-position: left center;
  padding-right: 30px;
  padding-left: 30px;
}

/*
--------------------------------------------------
アイコン表示位置２【文字列の先頭末尾】
--------------------------------------------------
*/
.c-ico--start,
.c-ico--end {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: 24px auto;
}

.c-ico--start {
  background-position: left center;
  padding-left: 30px;
  text-decoration: inherit;
}

.c-ico--end {
  background-position: right center;
  padding-right: 30px;
  text-decoration: inherit;
}

/*
--------------------------------------------------
汎用アイコン
--------------------------------------------------
*/
.c-icon--xxxxx {
  background-image: url("xxxxxxxxxxxxxxx");
}

/*
===============================================================================
■ fontawesome表示用CSSライブラリ
===============================================================================
*/

.c-icoFonawe__arrow01--start {
	position: relative;
	display: block;
	padding-left: 1em;
	&:before {
		font-family: FontAwesome;
		content : "\f138";
		position: absolute;
		left: 0;
	}
}

.c-icoFonawe__arrow02--end {
	position: relative;
	&:after {
		font-family: FontAwesome;
		content : "\f105";
	}
}



























@charset "UTF-8";
/* ----------------------------------------------------------------
privacyページのみのスタイル
-----------------------------------------------------------------*/
#privacy {
    .main-visual {
        background: url("../images/privacy_main_01.jpg");
        background-size: cover;
    }
    .wrap {
        opacity: 0;
        background-color: $clrWht;
        padding: 80px 0;
        line-height: 2em;
        &.line {
            border-top: 5px solid $clrBorder03;
        }

        .inner {
            width: 850px;
            margin: 0 auto;
        }
        h2 {
            font-size: 24px;
            margin: 0px 0 65px;
            text-align: center;
        }
        .mb50 {
            margin: 0 0 50px 0;
        }
        .textWrap {
            margin: 0 0 30px 0;
            padding: 0 0 10px 0;
            h3 {
                font-size: 20px;
                color: $clrBlck;
                border-bottom: 1px solid $clrBorder04;
                margin: 0 0 20px 0;
                padding: 0 0 10px 0;
                &:after {
                    border: none;
                }
            }
            ul {
                margin: 15px 0 0 0;
                li {
                    padding-left: 1em;
                    text-indent: -1em;
                }
            }
        }
        .imgWrap {
            margin: 0 0 35px 0;
        }
        .nameWrap {
            text-align: right;
            margin: 0 0 40px 0;
        }
        .btnWrap {
            a {
                &:hover {
                    opacity: 0.8;
                }
            }
        }
    }
}

@media #{$querySp} {
    #privacy {
        .main-visual {
            background-repeat: no-repeat;
            background-size: cover;
            .ttlWrap{
                width: 182px;
            }
        }
        .wrap {
            padding: 40px 0;
            &.line {
                border-top: 5px solid $clrBorder03;
            }

            .inner {
                width: 90%;
                margin: 0 auto;
            }
            h2 {
                font-size: 24px;
                margin: 0px 0 32.5px;
            }
            .mb50 {
                margin: 0 0 25px 0;
            }
            .textWrap {
                margin: 0 0 15px 0;
                padding: 0 0 5px 0;
                h3 {
                    font-size: 14px;
                    margin: 0 0 10px 0;
                    &:after {
                        border: none;
                    }
                }
                ul {
                    margin: 7px 0 0 0;
                }
            }
            .imgWrap {
                margin: 0 0 35px 0;
            }
            .nameWrap {
                margin: 0 0 20px 0;
            }
            .btnWrap {
                a.btn_01 {
                    width: 100%;
                }
            }
        }
    }
}

@charset "UTF-8";
/* ----------------------------------------------------------------
TOPページのみのスタイル
-----------------------------------------------------------------*/
.main {
    margin: 0 0 80px 0;
}

.topicsContent {
    .inner {
        @include clearfix;
    }
}
.topicsBox {
    opacity: 0;
}
.main_slider_pc {
    display: block;
    .slick-slide {
        display: block;
        img {
            float: right;
            min-height: 700px;
        }
    }
    &.slick-slider {
        display: block;
        padding: 0 0 0 210px;
        max-height: 700px;
    }
    .slider_01 {
        background: url("../images/main_visual_01.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 700px;
    }
    .slider_02 {
        background: url("../images/main_visual_02.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 700px;
    }
    .slider_03 {
        background: url("../images/main_visual_03.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        min-height: 700px;
    }
}
.main_slider_sp {
    display: none;
    .slick-slide {
        display: none;
    }
    &.slick-slider {
        display: none;
    }
}
.mainTtlWrap {
    margin: 0 0 40px 0;
    position: absolute;
    top:30%;
    left:0;
    width: 100%;
    min-height: 270px;
    .ttl_1 {
        padding: 20px 20px 20px 125px;
        background-color: $clrWht;
        position: absolute;
        top: 0;
        left: 0%;
        opacity: 0;
        transform: translate(-100px,0);
        -webkit-transform: translate(-100px,0);
        transition-duration:1s;
        &.on{
            opacity: 1;
            transform: translate(0,0);
            -webkit-transform: translate(0,0);
        }
    }
    .ttl_2 {
        padding: 20px 20px 20px 125px;
        background-color: $clrWht;
        color: $bgclr04;
        font-size: 18px;
        position: absolute;
        top: 40%;
        left: 0%;
        opacity: 0;
        transform: translate(-100px,0);
        -webkit-transform: translate(-100px,0);
        transition-duration:1s;
        font-weight: bold;
        &.on{
            opacity: 1;
            transform: translate(0,0);
            -webkit-transform: translate(0,0);
        }
    }
    .btn {
        position: absolute;
        padding: 0 0 0 180px;
        bottom: 0;
        left: 20px;
        opacity: 0;
        transform: translate(-100px,0);
        -webkit-transform: translate(-100px,0);
        transition-duration:4s;
        &.on{
            opacity: 1;
            transform: translate(0,0);
            -webkit-transform: translate(0,0);
        }
        .btn_01 {
            width: 190px;
        }
    }
    .imgWrap {
        img {
            width:100%;
        }
    }
}

.paraContent {
    .paraBox {
        position: relative;
        padding: 56px 0;
        // min-height: 580px;
        .contentWrap {
            position: absolute;
            top:0;
            max-width: 450px;
            z-index: 5;
            margin: 55px 0 0 0;
            padding: 50px;
            background-color: $clrWht;
            .ttl {
                margin: 0 0 20px 0;
                span {
                    font-size: 16px;
                    color: $clrBlck;
                    margin: 10px 0 0 0;
                    display: block;
                }
            }
            .btn_01 {
                width: 190px;
                margin: 50px 0 0 0;
            }
            .text {
                font-size: 13px;
            }
        }
    }
    .img_l {
        min-height: 435px;
        .contentWrap {
            right:0;
        }
        .imgWrap {
            text-align: left;
        }
    }
    .img_r {
        min-height: 580px;
        .contentWrap {
            left:0;
        }
        .imgWrap {
            text-align: right;
        }
    }
    .imgWrap {
        z-index:1;
        position: relative;
        .pala_01 {
            position: absolute;
            top: 0;
            right: 0;
        }
        .pala_02 {
            position: absolute;
            top: 0;
            left: 0;
        }
    }
}

@media #{$querySp} {
    #top {
        &.contentWrap {
            padding: 0;
        }
    }
    .main {
        margin: 0 0 35px 0;
        position: relative;
    }
    .topicsContent {
        margin: 0 0 40px 0;
    }

    .main_slider_pc {
        display: none;
        .slick-slide {
            display: none;
        }
        &.slick-slider {
            display: none;
        }
    }
    .main_slider_sp {
        display: block;
        padding: 0 0 0 15px;
        max-height: 492px;
        .slick-slide {
            display: block;
        }
        &.slick-slider {
            display: block;
        }
        .sp_slider_01 {
            background: url("../images/main_visual_sp_01.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            height: 492px;
        }
        .sp_slider_02 {
            background: url("../images/main_visual_sp_02.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            height: 492px;
        }
        .sp_slider_03 {
            background: url("../images/main_visual_sp_03.jpg");
            background-size: cover;
            background-repeat: no-repeat;
            background-position: bottom;
            height: 492px;
        }
        .slick-slide {
            height: 492px;
            img {
            }

        }
    }
    .mainTtlWrap {
        margin: 0 0 20px 0;
        position: absolute;
        top: 260px;
        left:0;
        width: 100%;
        min-height: 320px;
        .ttl_1 {
            // width: 50%;
            width: 229px;
            padding: 17px 15px;
            background-color: $clrWht;
            position: absolute;
            top: 0;
            left: 0%;
            opacity: 0;
            transform: translate(-100px,0);
            -webkit-transform: translate(-100px,0);
            transition-duration:1s;
            &.on{
                opacity: 1;
                transform: translate(0,0);
                -webkit-transform: translate(0,0);
            }
            img {
                width: 100%;
            }
        }
        .ttl_2 {
            // width: 70%;
            width: 270px;
            padding: 13px 15px;
            background-color: $clrWht;
            color: $bgclr04;
            font-size: 14px;
            font-weight: bold;
            position: absolute;
            top: 66px;
            left: 0%;
            opacity: 0;
            transform: translate(-100px,0);
            -webkit-transform: translate(-100px,0);
            transition-duration:1s;
            &.on{
                opacity: 1;
                transform: translate(0,0);
                -webkit-transform: translate(0,0);
            }
        }
        .btn {
            width: 25%;
            position: absolute;
            padding: 0;
            top: 145px;
            left: 115px;
            bottom: inherit;
            opacity: 0;
            transform: translate(-100px,0);
            -webkit-transform: translate(-100px,0);
            transition-duration:1s;
            &.on{
                opacity: 1;
                transform: translate(0,0);
                -webkit-transform: translate(0,0);
            }
            .btn_01 {
                width: 100%;
                padding: 7.5px 0;
            }
        }
        .imgWrap {
            img {
                width:100%;
            }
        }
    }

    .paraContent {
        .inner {
            padding: 0;
        }
        .paraBox {
            position: relative;
            padding: 0;
            margin: 0 0 50px 0;
            .contentWrap {
                width: 90%;
                position: inherit;
                top:0;
                left:0;
                max-width: 450px;
                z-index: 5;
                padding: 40px 15px;
                background-color: $clrWht;
                // transform: translateY(-50%) translateX(-50%);
                .ttl {
                    margin: 0 0 35px 0;
                    text-align: center;
                    img {
                        width: 204px;
                        margin: 0 auto;
                    }
                    span {
                        font-size: 14px;
                        margin: 5px 0 0 0;
                    }
                }
                .btn_01 {
                    width: 161px;
                    margin: 30px auto 0;
                }
            }
        }
        .img_l {
            min-height: auto;
            .paraboxInner {
                padding: 0 15px;
            }
            .contentWrap {
                position: relative;
                z-index: 5;
                margin: -45px auto 0;
                top: 0;
                left: 0;
                width: 100%;
                max-width: initial;
                padding: 40px 15px;
            }
            .imgWrap {
                position: initial;
                top: 0;
            }
        }
        .img_r {
            min-height: auto;
            .paraboxInner {
                padding: 0 15px;
            }
            .contentWrap {
                position: relative;
                z-index: 5;
                margin: -45px auto 0;
                top: 0;
                left: 0;
                width: 100%;
                max-width: initial;
                padding: 40px 15px;

            }
            .imgWrap {
                position: initial;
                top: 0;
            }
        }
        .imgWrap {
            z-index:1;
            position: relative;
            .pala_01 {
                width: 100%;
                position: initial;
                top: 0;
                right: 0;
            }
            .pala_02 {
                width: 100%;
                position: initial;
                top: 0;
                left: 0;
            }
        }
    }
}
